import React, { useState, useEffect } from 'react';
import { Badge, Typography, Carousel, Image, Button } from 'antd';
import { AlertFilled, DashboardFilled, SlidersFilled } from '@ant-design/icons';
import tickAsset1 from '../../assets/tick.e14cb23e178b8d3acd8f.png';
import tickAsset2 from '../../assets/tick.e14cb23e178b8d3acd8f34.png';
import messengerIcon from '../../assets/facebook-messenger-icon.png';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { Notification } from '../../components';
import home from '../../assets/banner.jpg';
import imgtab1 from '../../assets/icon_profit_borrow1.svg';
import imgtab2 from '../../assets/icon_profit_borrow2.svg';
import imgtab3 from '../../assets/icon_profit_borrow3.svg';
import imgtab4 from '../../assets/icon_profit_borrow4.svg';
import imgtab5 from '../../assets/icon_profit_borrow5.svg';
import banner1 from '../../assets/Vay-tien-qua-app-mbbank-4.png';
import './Home.scss';
import { useDispatch, useSelector } from 'react-redux';
import connectCSKH from '../../utils/connectCSKH';
import { message } from 'antd';
import api from '../../api';
import * as actions from '../../redux/actions/auth';

const arrayText = [
  <Typography.Text type="danger">097***4 đã rút 35.000.000 đ</Typography.Text>,
  <Typography.Text type="danger">033***8 đã rút 30.000.000 đ</Typography.Text>,
  <Typography.Text type="danger">087***3 đã rút 45.000.000 đ</Typography.Text>,
  <Typography.Text type="danger">033***1 đã rút 100.000.000 đ</Typography.Text>,
  <Typography.Text type="danger">035***5 đã được duyệt hồ sơ</Typography.Text>,
  <Typography.Text type="danger">033***5 đã rút 80.000.000 đ</Typography.Text>,
  <Typography.Text type="danger">093***1 đã rút 15.000.000 đ</Typography.Text>,
  <Typography.Text type="danger">039***9 đã được duyệt hồ sơ</Typography.Text>,
  <Typography.Text type="danger">086***1 đã rút 55.000.000 đ</Typography.Text>,
  <Typography.Text type="danger">039***6 đã rút 120.000.000 đ</Typography.Text>,
  <Typography.Text type="danger">035***2 đã được duyệt hồ sơ</Typography.Text>,
  <Typography.Text type="danger">036***7 đã được duyệt hồ sơ</Typography.Text>,
];

export default function Home() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state._auth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedCSKHLink, setSelectedCSKHLink] = useState(null);
  const [loadingCSKHLink, setLoadingCSKHLink] = useState(true);
  const [buttonBlink, setButtonBlink] = useState(false);
  const [currentRequest, setCurrentRequest] = useState({});
  const handleMessengerClick = () => {
    window.open('https://web.facebook.com/profile.php?id=100084311648551', '_blank');
  };
  useEffect(() => {
    //  const randomIndex = Math.floor(Math.random() * facebookSupportLinks.length);
    //  setSelectedCSKHLink(facebookSupportLinks[randomIndex]);
    loadProfile();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % arrayText.length);
    }, 1800);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const blinkInterval = setInterval(() => {
      setButtonBlink((prevBlink) => !prevBlink);
    }, 500);

    return () => clearInterval(blinkInterval);
  }, []);

  const handleContactSupport = () => {
    if (selectedCSKHLink) {
      window.open(selectedCSKHLink, '_blank', 'noopener,noreferrer');
    } else {
      message.info('Đang tải liên kết hỗ trợ. Vui lòng thử lại sau.');
    }
    return;
  };
  const loadProfile = async () => {
    const { data } = await api.get('/users/profile');
    dispatch(actions.initialLogin(data.data));
    loadCurrentRequest();
    if (data.data && data.data.sale) {
      setSelectedCSKHLink(data.data.sale.phone);
      setLoadingCSKHLink(false);
      localStorage.setItem('selectedLink', data.data.sale.phone);
    }
  };

  const loadCurrentRequest = async () => {
    try {
      const { data } = await api.get('/requests/lasted');
      setCurrentRequest(data?.data ? data.data : {});
    } catch (error) {
      setCurrentRequest({});
      console.log(error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="container"
    >
      <div className="header-content">
        <div>
          <Typography.Text className="text-head">Xin chào, </Typography.Text>
          <br />
          <Typography.Text className="text-head">
            {profile?.kyc?.name || profile.phone}
          </Typography.Text>
        </div>
        { <div style={{ marginLeft: 'auto', textAlign: 'right' }}>
          <Typography.Text className="text-head">Hotline:</Typography.Text>
          <br />
          <Typography.Text className="text-head">
            1900.9999.53
          </Typography.Text>
        </div> }
        <motion.div
          whileTap={{ scale: 0.9, opacity: 0.7 }}
          onClick={() => history.push('/notifications')}
          style={{
            marginLeft: '10px',
            height: '40px',
            width: '40px',
            textAlign: 'center',
            lineHeight: '40px',
          }}
        >
          <Notification />
        </motion.div>
      </div>
      <div className="noti-text-container">
        <Typography.Text className="noti-text">
          {arrayText[currentIndex]}
        </Typography.Text>
      </div>
      <Carousel autoplay autoplaySpeed={30000} className="carousel-container">
        <div>
          <div className="carousel slider1">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider2">
            <div className="sl-text-container"></div>
          </div>
        </div>
      </Carousel>
      <Button
        className={`choose-btn ant-btn ant-btn-default`}
        onClick={() => {
          history.push('/vay');
        }}
      >
        <Typography.Text style={{ color: '#fff', fontSize: 18 }}>
          Đăng ký khoản vay 
        </Typography.Text>
      </Button>
      <div style={{ padding: '5px 10px 5px 10px' }}>
        <Tab title={'➤ Lãi suất thấp 1%/tháng'} image={imgtab1} />
        <Tab title={'➤ Giải ngân nhanh chóng'} image={imgtab2} />
        <Tab title={'➤ Không giấy tờ gốc, không thế chấp'} image={imgtab3} />
        <Tab title={'➤ Thủ tục online dễ dàng 5 phút'} image={imgtab4} />
        <Tab title={'➤ Không phí ẩn, không phí phát sinh'} image={imgtab5} />
      </div>
      {/* <div className="s-line"></div>
      <div
        style={{
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: '800',
          textTransform: 'uppercase',
          color: '#0092ff',
          padding: '10px',
        }}
      >
        Về Chúng tôi
      </div> */}
      <Carousel autoplay autoplaySpeed={2800} className="carousel-container ">
        <div>
          <div className="carousel carousel-bottom slider6">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel carousel-bottom slider7">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel carousel-bottom slider8">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel carousel-bottom slider9">
            <div className="sl-text-container"></div>
          </div>
        </div>
      </Carousel>
      <div className="s-line"></div>
      <div className="messenger-icon" onClick={handleMessengerClick}>
        <Image src={messengerIcon} preview={false} style={{ width: 50, height: 50}}/>
      </div>      
      <div
        style={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div style={{ padding: '0 10px' }}>
          <Image src={tickAsset1} preview={false} style={{ maxWidth: 100 }} />
          <Image src={tickAsset2} preview={false} style={{ maxWidth: 100 }} />
        </div>
        <Typography.Text
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#555',
            textAlign: 'center',
            padding: '0 10px',
          }}
        >
          ® Bản quyền thuộc về
          <br />
          CÔNG TY TNHH TÀI CHÍNH EXIMBANK
          <br />
          Hotline 247: 1900.9999.53
          <br />
          Tất cả các quyền được bảo hộ
        </Typography.Text>
      </div>
    </motion.div>
  );
}

const Tab = ({ title, onClick, icon, image }) => {
  return (
    <motion.div
      whileTap={{ scale: 0.96, opacity: 0.3, border: '1px solid #0092ff' }}
      onClick={onClick}
      style={{
        margin: '10px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '7px 15px',
        border: '1px dashed #0092ff',
        borderRadius: 0,
      }}
    >
      <Typography.Text
        style={{ flex: 1, fontSize: 15, color: '#0092ff', fontWeight: 500 }}
      >
        {title}
      </Typography.Text>
      {icon}
      {image ? (
        <Image
          src={image}
          preview={false}
          style={{
            height: '35px',
            width: '35px',
            objectFit: 'contain',
            margin: '0 0 0 15px',
          }}
        />
      ) : (
        <></>
      )}
    </motion.div>
  );
};
