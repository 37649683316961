import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import './cskh.scss';
import cskh from '../../assets/cskh.png';
import { Image, Typography, Button } from 'antd';
import zalo from '../../assets/zalo.png';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import zlcskh from '../../utils/connectCSKH';
export default function Cskh() {
  const router = useHistory();
  return (
    <div className="container">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 10,
          background: '#0092ff',
          borderBottom: '1px solid #eee',
        }}
      >
        <motion.div
          whileTap={{ scale: 0.95, x: -10 }}
          onClick={() => router.replace('/')}
          style={{ padding: 0 }}
        >
          <LeftOutlined style={{ fontSize: 25, color: '#555' }} />
        </motion.div>
        <Typography.Text
          strong
          style={{ fontWeight: 700, fontSize: 18, marginRight: 25, color: '#fff' }}
        >
          Dịch vụ hỗ trợ
        </Typography.Text>
        <div></div>
      </div>
      <div className="body">
        <div className="iframe-container">
          <iframe
            src="https://hotro.giaiphaptaichinhex.com/"
            width="100%"
            height="650px"
            frameBorder="0"
            allowFullScreen
            title="Embedded Frame"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
